<template>
  <text-field
    ref="input"
    type="text"
    class="number-field"
    :value="display"
    :input-value="internalValue"
    :prevent="prevent"
    v-bind="$props"
    v-on="listeners"
  >

    <template v-for="name in slots" :slot="name">
      <slot :name="name"/>
    </template>

    <template v-for="name in scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>

    <template v-slot:append>
      <slot name="append"/>
      <div v-if="!hideControls && isFocused" class="input__controls">
        <v-btn @click="sum" :ripple="false" elevation="0" x-small icon>
          <v-icon v-text="sumIcon" small/>
        </v-btn>
        <v-btn @click="less" :ripple="false" elevation="0" x-small icon>
          <v-icon v-text="lessIcon" small/>
        </v-btn>
      </div>
    </template>

  </text-field>
</template>

<script>
import TextField from '../TextField';
import { componentProps, digits, round, countChars } from '@/utils';
import { keyCodes } from 'vuetify/lib/util/helpers';
import config from './defaults';

const KEY_CODES = Object.values( keyCodes );
const defaultProps = {
  ...TextField.props,
  min: [ Number, String ],
  max: [ Number, String ],
  int: Boolean,
  miller: Boolean,
  positive: Boolean,
  hideControls: Boolean,
  negative: {
    type: Boolean,
    default: true
  },
  millerSeparator: {
    type: String,
    default: '.'
  },
  decimalSeparator: {
    type: String,
    default: ','
  },
  decimals: [ Number, String ],
  length: [ Number, String ],
  step: [ Number, String ],
  sumIcon: {
    type: String,
    default: 'mdi-plus'
  },
  lessIcon: {
    type: String,
    default: 'mdi-minus'
  }
};

delete defaultProps.type;
delete defaultProps.prevent;

export default {
  name: 'NumberField',
  components: { TextField },
  props: componentProps( defaultProps, config ),
  data() {
    return {
      display: '',
      internalValue: undefined,
      decimalKey: false,
      isFocused: false,
      slots: [
        'append-outer',
        'prepend',
        'prepend-inner',
        'progress',
        'extend'
      ],
      scopedSlots: [
        'message',
        'counter'
      ],
    }
  },
  computed: {
    canNegative() {
      return this.positive !== true && this.negative !== false;
    },
    computedMin() {
      let min = Number(this.min);
      return isNaN(min) ? -Infinity : min;
    },
    computedMax() {
      let max = Number(this.max);
      return isNaN(max) ? Infinity : max;
    },
    computedStep() {
      return Number(this.step) || 0;
    },
    computedDecimals() {
      let dec = Number(this.decimals);
      return isNaN(dec) ? undefined : dec;
    },
    computedLength() {
      let length = Number(this.length);
      return isNaN(length) ? undefined : length;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        keydown: this.onKeyDown,
        blur: this.onBlur
      };
    }
  },
  watch: {
    value( value ) {
      this.setValue( value, true );
    }
  },
  methods: {
    /** @public */
    focus() {
      this.$refs.input && this.$refs.input.focus();
    },
    /** @public */
    blur() {
      this.$refs.input && this.$refs.input.blur();
    },
    cutString( str ) {
      let index = str.lastIndexOf( this.decimalSeparator );
      let index2 = str.lastIndexOf('.');
      if ( this.miller ) {
        if ( this.millerSeparator === '.' ) {
          if ( this.decimalSeparator !== '.' ) {
            return index !== -1
              ? [ str.slice( 0, index ), str.slice( index + 1 ) ]
              : [ str ];
          }
        }
      }
      if ( index2 !== -1 ) {
        return [ str.slice( 0, index2 ), str.slice( index2 + 1 ) ];
      } else if ( index !== -1 ) {
        return [ str.slice( 0, index ), str.slice( index + 1 ) ];
      }
      return [ str ];
    },
    cleanString( str ) {
      let [ integer, decimal ] = this.cutString( str );
      integer = ( integer || '0' ).replaceAll( this.millerSeparator, '' );
      return ( integer || '0' ) + ( decimal ? '.' + decimal : '' );
    },
    parseValue( value ) {

      if ( ! value && value !== 0 ) return '';
      else if ( typeof value === 'number' ) value = String( value );
      else if ( typeof value === 'string' ) value = this.cleanString( value );

      if ( ! this.canNegative )
        value.replace( '-', '' );

      if ( this.int ) {
        value = parseInt( value );
        return isNaN( value ) ? '' : value.toString();
      }

      return value;
    },
    parseDisplay( value ) {
      const dec = this.decimalSeparator || '';
      var [ integer, decimal ] = this.cutString( String( value ));
      return this.parseMiller( integer ) + ( decimal ? dec + decimal : '' );
    },
    parseMiller( str ) {
      const mil = this.millerSeparator || '';
      if ( this.miller && mil ) {
        var millers = [];
        while ( str.length ) {
          millers.unshift( str.slice(-3));
          str = str.slice( 0, -3 );
        }
        str = millers.join( mil );
      }
      return str;
    },
    computeValue( value, limit ) {

      value = parseFloat( this.parseValue( value ));
      if ( isNaN( value )) return undefined;
      if ( ! limit ) return value;

      if ( this.computedMax != null ) value = Math.min( value, this.computedMax );
      if ( this.computedMin != null ) value = Math.max( value, this.computedMin );

      if ( this.computedStep > 0 ) {
        let min = this.computedMin || 0;
        value = Math.round( min + ( value / this.computedStep )) * this.computedStep;
      }

      if ( this.computedDecimals != null ) {
        value = round( value, this.computedDecimals );
      }

      if ( this.int ) value = Math.floor( value );
      if ( value < 0 && ! this.canNegative ) value = 0;

      return value;
    },
    setValue( value, limit ) {
      if ( ! value && value !== 0 ) {
        this.internalValue = undefined;
        this.display = undefined;
      } else if ( ! this.decimalKey && value !== '-' ) {
        this.internalValue = this.computeValue( value, limit );
        this.display = this.parseDisplay( this.internalValue, limit ) || undefined;
      }
      this.decimalKey = false;
    },
    setDisplay( value ) {

      if ( value == null ) return this.display = '';

      value = String( value );

      if ( this.computedDecimals != null ) {
        value = value.split('.');
        value[1] = digits( value[1], this.computedDecimals, true );
        value = value.join('.');
      }

      if ( this.computedLength != null ) {
        value = value.split('.');
        value[0] = digits( value[0], this.computedLength );
        value = value.join('.');
      }

      return this.setInput(value);
    },
    setInput( value ) {
      this.display = value;
      const { input } = this.$refs;
      if ( ! input ) return;
      let el = input.$el.querySelector('input');
      if ( el ) setTimeout(() => el.value = value );
    },
    onInput( value, limit ) {
      if ( value === '-' ) return;
      this.setValue( value, limit );
      this.$emit( 'input', this.internalValue );
    },
    onKeyDown( event ) {
      if ( event.keyCode === keyCodes.up ) {
        this.sum( event );
      } else if ( event.keyCode === keyCodes.down ) {
        this.less( event );
      }
      this.$emit( 'keydown', event );
    },
    onBlur(e) {
      this.setValue( this.internalValue, true );
      e && this.$emit( 'blur', e );
    },
    sum( event ) {
      let step = 1;
      if ( event.shiftKey ) step *= 10;
      if ( event.ctrlKey ) step *= 10;
      event.preventDefault();
      this.onInput(( this.internalValue || 0 ) + step, true );
    },
    less( event ) {
      let step = 1;
      if ( event.shiftKey ) step *= 10;
      if ( event.ctrlKey ) step *= 10;
      event.preventDefault();
      this.onInput(( this.internalValue || 0 ) - step, true );
    },
    prevent( event ) {

      const value = ( event.target && event.target.value ) || '';
      if ( ! isNaN( parseInt( event.key ))) {
        return this.computedMax != null
          ? this.internalValue === this.computedMax
          : false;
      }

      // Negative
      if ( this.canNegative && event.key === '-' )
        return !!value || this.computedMin === 0;

      // Decimal
      if ( ! this.int ) {
        if (
          this.decimalSeparator === event.key
          || ( ! this.miller && event.key === '.')
          || ( this.miller && this.millerSeparator !== '.' && event.key === '.' )
        ) {
          this.decimalKey = true;
          return countChars( value, this.decimalSeparator ) > 0;
        }
      }

      if ( event.ctrlKey ) return false;
      return ! KEY_CODES.includes( event.keyCode );
    },
  },
  beforeMount() {
    this.setValue( this.value, true );
  },
  mounted() {
    this.$watch('$refs.input.isFocused', v => this.isFocused = v );
  }
}
</script>

<style>
.number-field .input__controls {
  display: flex;
}
</style>
