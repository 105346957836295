<template>
  <text-field
    ref="field"
    type="text"
    class="maskfield"
    v-bind="$props"
    v-on="listeners"
    :value="display"
    :prevent="prevent"
  >

    <template v-for="name in slots" :slot="name">
      <slot :name="name"/>
    </template>

    <template v-for="name in scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>

  </text-field>
</template>

<script>
import TextField from '../TextField';
import { componentProps } from '@/utils';
import { keyCodes } from 'vuetify/lib/util/helpers';
import config from './defaults';

const KEY_CODES = Object.values( keyCodes );
const ALPHA_REG = /[a-z]/i;
const NUMBER_REG = /\d/;
const OPTIONS = { number: 'd', alpha: 'a', special: 's' };

const defaultProps = {
  ...TextField.props,
  mask: String,
  options: Object,
  returnMasked: Boolean
};

delete defaultProps.type;
delete defaultProps.prevent;

export default {
  name: 'MaskField',
  inheritAttrs: false,
  components: { TextField },
  props: componentProps( defaultProps, config ),
  data() {
    return {
      slots: [
        'append',
        'append-outer',
        'prepend',
        'prepend-inner',
        'progress',
        'extend'
      ],
      scopedSlots: [
        'message',
        'counter'
      ],
      display: undefined,
      internal: undefined,
      isFocused: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput
      };
    },
    computedOptions() {
      return {
        ...OPTIONS,
        ...this.options
      }
    },
    isComplete() {
      return this.mask && ( this.display || '' ).length >= this.mask.length;
    }
  },
  watch: {
    value: 'setValue',
    mask: 'setValue',
    options: 'setValue'
  },
  methods: {
    setValue( value ) {
      if ( ! value ) {

        this.internal = undefined;
        this.display = undefined;

      } else if ( ! this.mask ) {

        this.internal = value;
        this.display = value;

      } else {

        var r = this.computeValue( value );
        this.internal = r.value || undefined;
        this.display = r.display || undefined;
      }
    },
    computeValue( value ) {

      value = String( value );
      const { mask } = this;

      var result = { value: '', display: '' };
      var length = Math.max( mask.length, value.length );

      for ( var m = 0, c = 0, a, b, mode; m < length; m++ ) {

        if ( m >= mask.length ) break;
        if ( c >= value.length ) break;

        a = value.charAt(c);
        b = mask.charAt(m);
        mode = this.getMode(b);

        if ( mode ) {
          if ( this.validateChar( a, mode )) {
            result.display += a;
            result.value += a;
            c++;
          } else {
            return result;
          }
        } else if ( a === b ) {
          result.display += a;
          result.value += a;
          c++;
        } else {
          result.display += b;
        }
      }

      return result;
    },
    getMode( char ) {
      const opts = this.computedOptions;
      for ( var key in opts ) {
        if ( opts[key] === char ) return key;
      }
    },
    getNextMode() {
      const value = this.display;
      if ( ! this.mask ) return;
      if ( ! value ) return this.getMode( this.mask.charAt( 0 ));
      else {
        for ( var m = 0, c = 0, a, b, mode; m < this.mask.length; m++ ) {

          a = value.charAt(c);
          b = this.mask.charAt(m);
          mode = this.getMode(b);

          if ( mode ) {
            if ( c >= value.length ) return mode;
            if ( this.validateChar( a, mode )) c++;
            else return;
          } else if ( a === b ) c++;
        }
      }
    },
    validateChar( char, mode ) {
      switch ( mode ) {
        case 'number':
          return NUMBER_REG.test( char );
        case 'special':
          return !ALPHA_REG.test( char );
        default:
          return ALPHA_REG.test( char );
      }
    },
    prevent( event ) {

      if ( event.ctrlKey ) return false;
      if ( KEY_CODES.includes( event.keyCode )) return false;
      if ( this.isComplete ) return true;

      const mode = this.getNextMode();
      return !this.validateChar( event.key, mode );
    },
    onInput( value ) {
      this.setValue( value );
      this.$emit( 'input', this.returnMasked ? this.display : this.internal );
    },
    /** @public */
    focus() {
      this.$refs.field && this.$refs.field.focus();
    },
    /** @public */
    blur() {
      this.$refs.field && this.$refs.field.blur();
    }
  },
  beforeMount() {
    this.setValue( this.value );
  },
  mounted() {
    this.$watch( '$refs.field.isFocused', v => this.isFocused = v );
  }
};
</script>
