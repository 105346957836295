<template>
  <mask-field
    ref="field"
    class="timefield"
    :value="display"
    :mask="mask"
    :error="!isValid || error"
    :append-icon="( isFocused && appendIcon ) || undefined"
    v-bind="$props"
    v-on="listeners || on"
    return-masked
  >

    <template v-for="name in slots" :slot="name">
      <slot :name="name"/>
    </template>

    <template v-for="name in scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>

  </mask-field>
</template>

<script>
import MaskField from '../MaskField';
import { digits, componentProps } from '@/utils';
import { deepEqual } from 'vuetify/lib/util/helpers';
import config from './defaults';
//import moment from 'moment';

const FORMAT = 'hh:mm:ss';
const MAX_TIME = 86399999; // 23h 59m 59s 999ms

const defaultProps = {
  ...MaskField.props,
  returnDate: Boolean,
  completeOnBlur: {
    type: Boolean,
    default: true
  },
  format: {
    type: String,
    default: FORMAT
  },
  appendIcon: {
    type: String,
    default: 'mdi-clock-outline'
  },
  min: [ Number, String ],
  max: [ Number, String ]
};

delete defaultProps.mask;

export function numberToTime( value ) {

  if ( value > MAX_TIME ) return dateToTime( value );
  value = value || 0;

  const h = Math.floor( value / 3600000 );
  const m = Math.floor(( value - ( h * 3600000 )) / 60000 );
  const s = Math.floor(( value - ( h * 3600000 ) - ( m * 60000 )) / 1000 );
  const k = Math.floor( value - ( h * 3600000 ) - ( m * 60000 ) - ( s * 1000 ));
  return { h, m, s, k, complete: true };
}

export function dateToTime( value ) {
  value = new Date(value);
  return {
    h: value.getHours(),
    m: value.getMinutes(),
    s: value.getSeconds(),
    k: value.getMilliseconds(),
    complete: true
  };
}

export function stringToTime( value, format = FORMAT ) {

  let time = { h: '', m: '', s: '', k: '' };
  for ( var i = 0, k; i < format.length; i++ ) {
    k = format.charAt(i).toLowerCase();
    if ( k in time ) {
      time[k] += value.charAt(i);
    }
  }

  for ( k in time ) {
    time[k] = parseInt( time[k] );
    if ( isNaN( time[k] )) time[k] = 0;
  }

  return {
    ...time,
    complete: value.length === format.length
  };
}

export function timeToNumber( time ) {
  return (
    time.k
    + time.s * 1000
    + time.m * 60000
    + time.h * 3600000
  );
}

export function timeToString( time, format = FORMAT ) {

  let value = '';
  time = {
    h: digits( time.h, 2 ),
    m: digits( time.m, 2 ),
    s: digits( time.s, 2 ),
    k: digits( time.k, 3 )
  };

  for ( var i = 0, k; i < format.length; i++ ) {
    k = format.charAt(i).toLowerCase();
    if ( k in time ) {
      value += time[k].charAt(0);
      time[k] = time[k].slice(1);
    } else {
      value += k;
    }
  }
  return value;
}

export function getTime( value, format = FORMAT ) {
  if ( typeof value === 'number' ) {
    return numberToTime( value );
  } else if ( typeof value === 'string' ) {
    return stringToTime( value, format );
  } else if ( value instanceof Date ) {
    return dateToTime( value );
  }
  return undefined;
}

export function timeToDate( time ) {
  let d = new Date();
  return new Date(
    d.getFullYear(), d.getMonth(), d.getDate(),
    time.h, time.m, time.s, time.k
  );
}

export default {
  name: 'TimeField',
  inheritAttrs: false,
  components: { MaskField },
  props: componentProps( defaultProps, config ),
  data() {
    return {
      slots: [
        'append',
        'append-outer',
        'prepend',
        'prepend-inner',
        'progress'
      ],
      scopedSlots: [
        'message',
        'counter'
      ],
      display: undefined,
      time: undefined,
      isFocused: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur
      };
    },
    mask() {
      return this.format.replace(/[hms]/gi,'d') || 'dd:dd';
    },
    computedMin() {
      return this.min && timeToNumber( getTime( this.min, this.format ));
    },
    computedMax() {
      return this.max && timeToNumber( getTime( this.max, this.format ))
    },
    isValid() {
      if ( ! this.time ) return true;
      if ( ! this.time.complete ) return false;
      if ( this.time.m > 59 ) return false;
      if ( this.time.s > 59 ) return false;
      if ( this.time.k > 999 ) return false;
      return true;
    }
  },
  watch: {
    value( value, old ) {
      if ( deepEqual( value, old )) return;
      this.set( value );
    },
    time( time ) {
      let result = undefined;
      if ( time && this.isValid ) {
        result = this.returnDate
          ? timeToDate( time )
          : timeToNumber( time );
      }
      this.$emit( 'input', result );
    }
  },
  methods: {
    set( value ) {
      this.setValue( value );
      this.setDisplay();
    },
    setValue( value, limit ) {

      if ( value == null || value === '' ) value = undefined;
      else value = getTime( value, this.format );

      if ( limit && value ) {
        let min = this.computedMin;
        let max = this.computedMax;
        if ( min ) {
          value = getTime( Math.max( timeToNumber( value ), min ), this.format );
        }
        if ( max ) {
          value = getTime( Math.min( timeToNumber( value ), max ), this.format );
        }
      }

      this.time = value;
    },
    setDisplay() {
      this.display = this.time
        ? timeToString( this.time, this.format )
        : undefined;
    },
    onInput( value ) {
      this.display = value;
      this.setValue( value );
    },
    onBlur(e) {
      let { time } = this;
      if ( this.completeOnBlur && this.time ) {
        time = { h: 0, m: 0, s: 0, k: 0, ...time };
        this.setValue( timeToNumber( time ), true );
      } else {
        this.setValue( time, true );
      }
      e && this.$emit( 'blur', e );
    },
    /** @public */
    focus() {
      this.$refs.field && this.$refs.field.focus();
    },
    /** @public */
    blur() {
      this.$refs.field && this.$refs.field.blur();
    }
  },
  beforeMount() {
    this.set( this.value );
  },
  mounted() {
    this.$watch( '$refs.field.isFocused', v => this.isFocused = v );
  }
};
</script>
